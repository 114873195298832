<script>
import CalendarApiService from "@/services/calendarService";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { Sketch } from "vue-color";
import { required } from "vuelidate/lib/validators";
import UsersMultiSelect from "@/components/users-multi-select.vue";
import UserConfigMixin from "@/mixins/userConfigMixin";

/**
 * Dashboard Component
 */
export default {
  mixins: [UserConfigMixin],
  page: {
    title: "progress",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    Sketch,
    UsersMultiSelect,
  },
  data() {
    return {
      mode: null,
      colors: { r: 255, g: 0, b: 0 },
      showColorPicker: false,
      state: this.$route.name == "editProgress" ? "edit" : "create",
      isLoading: false,
      assignedUsers: [],
      items: [
        {
          text: "progress",
          href: "/configuration/progress",
        },
        {
          text: "",
          active: true,
        },
      ],
      linkOptions: [
        {
          code: "Delivery",
          fr: "Livraison",
          en: "Delivery",
        },
        {
          code: "Installation",
          fr: "Installation",
          en: "Installation",
        },
        {
          code: "Production",
          fr: "Production",
          en: "Production",
        },
      ],
      progressForm: {
        id: this.$route.params.progressId,
        name: null,
        backSchedulingReferenceDate: null,
        color: "#FFC25D",
        unitType: null,
        unitTypeMax: null,
        unitTypeMin: null,
        numberOfSheetsMin: null,
        numberOfSheetsMax: null,
        dailyCapacities: [],
        moveLaterProgress: false,
        askToCompletePreviousProgress: false,
        includeSaturday: false,
        includeSunday: false,
      },
      capacityForm: {
        measureType: null,
        min: null,
        max: null,
      },
      unitTypeOptions: [],
      progressFormSubmit: false,
      capacitySubmit: false,
      capacityTypeOptions: [],
      users: [],
    };
  },
  validations: {
    progressForm: {
      name: { required },
      backSchedulingReferenceDate: { required },
    },
    capacityForm: {
      measureType: { required },
      min: { required },
      max: { required },
    },
  },
  async created() {
    await this.loadData();

    if (this.state == "edit") {
      this.initEditProgress();
    } else if (this.state == "create") {
      this.initCreateProgress();
    }
  },
  mounted() {},
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },
    title() {
      return this.$route.name == "editProgress"
        ? this.$t("general.editProgress")
        : this.$t("general.createProgress");
    },
    availableCapacityTypes() {
      let availableCapacityTypes = [];
      //return availableCapacityTypes;
      for (let capacityType of this.capacityTypeOptions) {
        let exist = this.progressForm.dailyCapacities.filter(function (c) {
          return c.capacityType.idPublic == capacityType.idPublic;
        });
        if (exist.length == 0) {
          availableCapacityTypes.push(capacityType);
        }
      }

      return availableCapacityTypes;
    },
  },
  watch: {
    locale: function () {
      //translate unit type
      for (let ut of this.unitTypeOptions) {
        ut.translate = this.$t("general." + ut.name.toLowerCase());
      }
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      if (this.isTaskManagementOrganisationUser) {
        CalendarApiService.getUsers().then((response) => {
          this.users = response.data.data;
        });
      }
      var promise1 = CalendarApiService.getAllCapacityTypes().then(
        (response) => {
          this.capacityTypeOptions = response.data.data;
        }
      );

      var promise2 = CalendarApiService.getAllUnitTypes().then((response) => {
        this.unitTypeOptions = response.data.data;
        for (let ut of this.unitTypeOptions) {
          this.$set(
            ut,
            "translate",
            this.$t("general." + ut.name.toLowerCase())
          );
        }
      });

      await Promise.all([promise1, promise2]).then(() => {
        this.isLoading = false;
      });
    },
    initEditProgress() {
      CalendarApiService.getProgressConfig(this.progressForm.id).then(
        (response) => {
          this.progressForm.name = response.data.data.name;
          this.progressForm.backSchedulingReferenceDate =
            this.linkOptions.filter(
              (o) => o.code == response.data.data.backSchedulingReferenceDate
            )[0];
          this.progressForm.color = response.data.data.colorHexa;
          this.progressForm.dailyCapacities =
            response.data.data.dailyCapacities;
          this.progressForm.unitType = response.data.data.unitType;
          if (this.progressForm.unitType) {
            this.$set(
              this.progressForm.unitType,
              "translate",
              this.$t(
                "general." + this.progressForm.unitType.name.toLowerCase()
              )
            );
          }

          this.progressForm.moveLaterProgress =
            response.data.data.moveLaterProgress;
          this.progressForm.askToCompletePreviousProgress =
            response.data.data.askToCompletePreviousProgress;
          this.progressForm.includeSaturday =
            response.data.data.includeSaturday;
          this.progressForm.includeSunday = response.data.data.includeSunday;
          this.assignedUsers = response.data.data.users.map((x) => x.userId);
        }
      );
    },
    initCreateProgress() {
      this.progressForm.unitType = this.unitTypeOptions[0];
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    updateColorProgress(color) {
      this.progressForm.color = color.hex;
    },
    submit() {
      this.progressFormSubmit = true;
      this.$v.progressForm.$touch();

      if (!this.$v.progressForm.$invalid) {
        this.isLoading = true;

        var model = {
          id: this.progressForm.id,
          colorHexa: this.progressForm.color,
          backSchedulingReferenceDate:
            this.progressForm.backSchedulingReferenceDate.code,
          name: this.progressForm.name,
          dailyCapacities: this.progressForm.dailyCapacities,
          unitType: this.progressForm.unitType,
          moveLaterProgress: this.progressForm.moveLaterProgress,
          askToCompletePreviousProgress:
            this.progressForm.askToCompletePreviousProgress,
          includeSaturday: this.progressForm.includeSaturday,
          includeSunday: this.progressForm.includeSunday,
          users: this.assignedUsers,
        };

        if (this.state == "create") {
          CalendarApiService.createProgress(model).then(() => {
            this.isLoading = false;
            this.$router.push({ name: "progressList" });
          });
        } else if (this.state == "edit") {
          CalendarApiService.updateProgress(model).then(() => {
            this.isLoading = false;
            this.$router.push({ name: "progressList" });
          });
        }
      }
    },
    submitCapacityForm() {
      this.capacitySubmit = true;
      this.$v.capacityForm.$touch();

      if (!this.$v.capacityForm.$invalid) {
        this.capacitySubmit = false;

        let model = {
          measureType: this.capacityForm.measureType,
          min: this.capacityForm.min,
          max: this.capacityForm.max,
        };

        this.progressForm.dailyCapacities.push(model);

        //reset form
        this.capacityForm.measureType = null;
        this.capacityForm.min = null;
        this.capacityForm.max = null;
      }
    },
    deleteCapacity(index) {
      this.progressForm.dailyCapacities.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "progressList" });
    },
    getTranslation(name) {
      return this.$t(name.toLowerCase());
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="card">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#f1b44c"
        :width="70"
        :height="70"
        loader="dots"
        :is-full-page="false"
      >
      </loading>
      <div class="card-body">
        <b-form class="row" @submit.prevent="submit" style="max-width: 960px">
          <div class="col-12 col-md-12">
            <b-form-group>
              <label>{{ $t("general.name") }}</label>
              <b-form-input
                :class="{
                  'is-invalid':
                    progressFormSubmit && $v.progressForm.name.$error,
                }"
                style="max-width: 300px"
                v-model="progressForm.name"
                :placeholder="$t('general.name')"
              >
              </b-form-input>
              <div
                v-if="progressFormSubmit && $v.progressForm.name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.progressForm.name.required">{{
                  $t("general.requiredValue")
                }}</span>
              </div>
            </b-form-group>
            <b-form-group style="flex-direction: column">
              <label>{{ $t("general.color") }}</label>
              <div class="mb-2" style="display: flex; align-items: center">
                <div
                  class="mr-2"
                  :style="{ background: progressForm.color }"
                  style="width: 27px; height: 27px; border-radius: 5px"
                ></div>
                <b-button
                  @click="toggleColorPicker()"
                  v-show="!showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.selectColor") }}</b-button
                >
                <b-button
                  @click="toggleColorPicker()"
                  v-show="showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.close") }}</b-button
                >
              </div>

              <Sketch
                v-show="showColorPicker"
                :value="progressForm.color"
                @input="updateColorProgress"
              />
            </b-form-group>
            <b-form-group>
              <label>{{ $t("general.backScheduolingReferenceDate") }}</label>
              <multiselect
                :validator="$v.progressForm.backSchedulingReferenceDate"
                :placeholder="$t('general.backScheduolingReferenceDate')"
                :class="{
                  'is-invalid':
                    progressFormSubmit &&
                    $v.progressForm.backSchedulingReferenceDate.$error,
                }"
                style="max-width: 300px"
                v-model="progressForm.backSchedulingReferenceDate"
                :options="linkOptions"
                :show-labels="false"
                :label="$i18n.locale"
                track-by="code"
              ></multiselect>
              <div
                v-if="
                  progressFormSubmit &&
                  $v.progressForm.backSchedulingReferenceDate.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.progressForm.backSchedulingReferenceDate.required"
                  >{{ $t("general.requiredValue") }}</span
                >
              </div>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="progressForm.moveLaterProgress"
                class="custom-checkbox"
                >{{ $t("general.moveLaterProgress") }}</b-form-checkbox
              >
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                v-model="progressForm.askToCompletePreviousProgress"
                class="custom-checkbox"
                >{{
                  $t("general.askToCompletePreviousProgress")
                }}</b-form-checkbox
              >
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                style="display: inline-block"
                v-model="progressForm.includeSaturday"
                class="custom-checkbox mr-4"
                >{{ $t("general.includeSaturday") }}</b-form-checkbox
              >

              <b-form-checkbox
                style="display: inline-block"
                v-model="progressForm.includeSunday"
                class="custom-checkbox"
                >{{ $t("general.includeSunday") }}</b-form-checkbox
              >
            </b-form-group>

            <hr class="mt-4" />
          </div>
          <div class="col-12">
            <h4 class="mt-2 mb-2">{{ $t("general.dailyCapacities") }}</h4>

            <form class="mb-4" @submit.prevent="submitCapacityForm">
              <div
                class="row zno-gutters"
                style="max-width: 700px; align-items: flex-end"
              >
                <div class="col-12 col-md-6 mt-2">
                  <div
                    class="form-group mb-0"
                    style="position: relative; top: 2px"
                  >
                    <label>{{ $t("general.capacity") }}</label>
                    <multiselect
                      :validator="$v.capacityForm.measureType"
                      :class="{
                        'is-invalid':
                          capacitySubmit && $v.capacityForm.measureType.$error,
                      }"
                      style="width: 100%"
                      v-model="capacityForm.measureType"
                      :options="measureTypes"
                      :show-labels="false"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span>{{ $t("general." + props.option) }}</span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span>{{ $t("general." + props.option) }}</span>
                      </template>
                    </multiselect>
                    <div
                      v-if="
                        capacitySubmit && $v.capacityForm.measureType.$error
                      "
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.capacityForm.measureType.required">{{
                        $t("general.requiredValue")
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-2 mt-2">
                  <b-form-group class="mb-0" style="position: relative">
                    <label>{{ $t("general.min") }}</label>
                    <b-form-input
                      type="number"
                      step="0.01"
                      :class="{
                        'is-invalid':
                          capacitySubmit && $v.capacityForm.min.$error,
                      }"
                      style="max-width: 90px"
                      v-model="capacityForm.min"
                      placeholder="Min"
                    ></b-form-input>
                    <div
                      v-if="capacitySubmit && $v.capacityForm.min.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.capacityForm.min.required">{{
                        $t("general.requiredValue")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-12 col-md-2 mt-2">
                  <b-form-group class="mb-0" style="position: relative">
                    <label>{{ $t("general.max") }}</label>
                    <b-form-input
                      type="number"
                      step="0.01"
                      :class="{
                        'is-invalid':
                          capacitySubmit && $v.capacityForm.max.$error,
                      }"
                      style="max-width: 90px"
                      v-model="capacityForm.max"
                      placeholder="Max"
                    ></b-form-input>
                    <div
                      v-if="capacitySubmit && $v.capacityForm.max.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.capacityForm.max.required">{{
                        $t("general.requiredValue")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-12 col-md-2 mt-2">
                  <b-button
                    style="height: 36px"
                    type="submit"
                    variant="primary"
                    >{{ $t("general.add") }}</b-button
                  >
                </div>
              </div>
            </form>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("general.capacity") }}</th>
                    <th style="text-align: center">{{ $t("general.min") }}</th>
                    <th style="text-align: center">{{ $t("general.max") }}</th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.delete") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(capacity, index) in progressForm.dailyCapacities"
                    :key="index"
                  >
                    <td>{{ $t("general." + capacity.measureType) }}</td>
                    <td>
                      <b-form-input
                        required
                        type="number"
                        step="0.01"
                        v-model="capacity.min"
                        placeholder="0"
                        style="max-width: 90px; margin: 0 auto"
                      >
                      </b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        required
                        type="number"
                        step="0.01"
                        v-model="capacity.max"
                        placeholder="0"
                        style="max-width: 90px; margin: 0 auto"
                      >
                      </b-form-input>
                    </td>
                    <td
                      @click="deleteCapacity(index)"
                      style="
                        text-align: center;
                        color: #f46a6a;
                        font-size: 1.8em;
                      "
                    >
                      <i
                        style="cursor: pointer"
                        class="mdi mdi-trash-can-outline"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="mt-4" />
          </div>
          <div class="col-12" v-if="isTaskManagementOrganisationUser">
            <h4 class="mt-2 mb-2">{{ $t("general.assignedUsers") }}</h4>
            <UsersMultiSelect
              v-model="assignedUsers"
              :users="users"
              trackBy="id"
            />
            <hr class="mt-4" />
          </div>

          <div class="col-12 pt-3">
            <b-button @click="cancel()" variant="light">Cancel</b-button>
            <b-button
              v-if="state == 'create'"
              type="submit"
              class="ml-1"
              variant="success"
              >{{ $t("general.create") }}</b-button
            >
            <b-button
              v-if="state == 'edit'"
              type="submit"
              class="ml-1"
              variant="success"
              >{{ $t("general.save") }}</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#layout-wrapper /deep/ .main-content {
  overflow: visible !important;
}
</style>
